
export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },

    {
        title: 'Document Category',
        key: 'DocumentCategory',
        minWidth: 80,
    },

	
]
